<script setup>
import { ref, onMounted, watch } from 'vue'
import PreLoader from './PreLoader.vue';

let trigger = ref(false);



const time = setTimeout(triggerFunction, 570);

function triggerFunction(){
  console.log("TRIGGERED");
  trigger.value = true;
}
 
//const show = ref(false)
 
const props = defineProps(['show', 'del'])
 
//console.log(props.show)

const shouldRender = ref(false);


</script>
 
 
<template>


<!--<transition name="slide" appear>
<div v-if="trigger && !props.show" class="testgroup">
 
  <div class="testsection"><PreLoader class="preloader"/></div>
</div>
</transition>-->


<transition name="slide" appear >
   <div v-if="trigger && !props.show" class="overlay-main slider" style="">
    <div class="overlay-content"><PreLoader name="fade" class="preloader"/></div>
  </div>
</transition>
 


<!--<transition name="slide" appear>
<div class="testgroup">
  <div class="testpreloader"><PreLoader/></div>
  <div class="testsection"></div>
</div>
</transition>-->
 
<!-- <transition name="fade" appear>
<div v-if="!props.show" class="whitespace"></div>
</transition>-->
 
</template>
 
 
<style lang="scss" scoped>

.preloader{
    height:250px !important;
    margin-bottom:-2px;
}
 
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}
.fade-enter-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
 
.slide-enter-active,
.slide-leave-active {
  transition: all .3s ease-out;
  //transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
 
.slide-enter-from,
.slide-leave-to {
  //transform: scaleY(0);
  //background-color:red;
  //margin-top:-50%;
  //opacity: 0;
  height:0px !important;
}
 
.whitelogo{
    height:40px;
    width:40px;
    margin-left:32px;
    margin-right:10px;
    z-index:3;
    background-color: #FFFFFF;
    -webkit-mask-image: url(../../../public/img/bopus/bopus_hat_only_grey.svg);
    mask-image: url(../../../public/img/bopus/bopus_hat_only_grey.svg);
}
.overlay-main{
    background-color:#006C3E;
    width:100%;
    position: fixed;
    z-index: 2;
    display:flex;
    justify-content:center;
    flex-direction:column;
    height:42%;
    top: 64px;
    left: 0px;
    right: 0px;

    @media screen and (min-width: 481px) and (max-width: 900px){
      top: 52px;
    }
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
      top: 44px;
    }
}
 
.overlay-nav{
   position:absolute;
   top:0;
   height:64px;
   display:flex;
   align-items:center;
   color:white;
}
 
.overlay-content{
    display:flex;
    align-items:center;
    align-items: flex-end !important; 
    height:100%;
}


 
</style>